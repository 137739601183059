import axios, { handleRequest } from "./kiddy-connector";
import qs from "qs";
import _ from "lodash";

const GetBundleListAPI = ({
  level,
  grade,
  subject,
  limit,
  lesson,
  publisher,
}) =>
  axios
    .get(
      `bundle?level=${level}&grade=${grade}&subject=${subject}&limit=${limit}&lesson=${lesson}&publisher=${publisher}`
    )
    .then((response) => response.list);
const GetBundleDetailAPI = ({ id }) =>
  axios.get(`bundle/${id}`).then((response) => {
    return response.bundle;
  });

const GetRelatedBundleAPI = ({ id }) =>
  axios.get(`bundle/relate/${id}`).then((response) => response.list);
const GetCoreFilter = () =>
  axios.get("master/coreFilter").then((response) => response.list);
const GetMyBundle = () =>
  axios.get("bundle/myBundle").then((response) => response.list);
const GetMyFavoriteBundle = () =>
  axios.get("bundle/myFavorite").then((response) => response.list);
const PatchFavoriteBundle = ({ id }) => axios.patch(`bundle/favorite/${id}`);
const PatchUnfavoriteBundle = ({ id }) =>
  axios.patch(`bundle/unfavorite/${id}`);
const GetFreeBundle = ({ id }) => axios.get(`bundle/getFree/${id}`);
const GetSearchBundleList = ({
  keyword,
  level = "",
  grade = "",
  subject = "",
  limit = "",
}) =>
  axios
    .get(
      `bundle/search?keyword=${keyword}&level=${level}&grade=${grade}&subject=${subject}&limit=${limit}`
    )
    .then((response) => response.list);
const PatchReviewBundle = ({ id, review }) =>
  axios.patch(`bundle/review/${id}`, { reviewPoint: review });
const PostPurchaseBundle = ({
  bundleId,
  encryptCard,
  cardHolderName,
  cardHolderEmail,
  paymentType,
  amount,
}) =>
  axios
    .post("bundle/purchase", {
      bundleId,
      encryptCard,
      cardHolderName,
      cardHolderEmail,
      paymentType,
      amount,
    })
    .then((response) => response);

export const getBundleList = ({
  level = "",
  grade = "",
  subject = "",
  lesson = "",
  publisher = "",
  limit = 100,
}) => {
  return handleRequest(
    GetBundleListAPI({ level, grade, subject, limit, lesson, publisher })
  );
};
export const getBundleDetail = ({ id }) => {
  return handleRequest(GetBundleDetailAPI({ id }));
};
export const getBundleRelated = ({ id }) => {
  return handleRequest(GetRelatedBundleAPI({ id }));
};
export const getCoreFilter = () => {
  return handleRequest(GetCoreFilter());
};
export const getMyBundle = () => {
  return handleRequest(GetMyBundle());
};
export const getMyFavoriteBundle = () => {
  return handleRequest(GetMyFavoriteBundle());
};
export const patchFavoriteBundle = ({ id }) => {
  return handleRequest(PatchFavoriteBundle({ id }));
};
export const patchUnfavoriteBundle = ({ id }) => {
  return handleRequest(PatchUnfavoriteBundle({ id }));
};
export const getFreeBundle = ({ id }) => {
  return handleRequest(GetFreeBundle({ id }));
};
export const getSearchBundleList = ({
  keyword,
  level,
  grade,
  subject,
  limit = 100,
}) => {
  return handleRequest(
    GetSearchBundleList({ keyword, level, grade, subject, limit })
  );
};
export const patchReviewBundle = ({ id, review }) => {
  return handleRequest(PatchReviewBundle({ id, review }));
};
export const postPurchaseBundle = ({
  bundleId,
  encryptCard,
  cardHolderName,
  cardHolderEmail,
  paymentType,
  amount,
}) => {
  return handleRequest(
    PostPurchaseBundle({
      bundleId,
      encryptCard,
      cardHolderName,
      cardHolderEmail,
      paymentType,
      amount,
    })
  );
};
