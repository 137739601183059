const authenVocab = {
  "Log in": "เข้าสู่ระบบ",
  "Sign up": "สมัครสมาชิก",
};
const examVocab = {
  Minutes: "นาที",
  times: " ครั้ง",
  Access: "จำนวนครั้งการเข้าทำ",
  Grade: "ระดับชั้น",
  "Questions ": "จำนวนข้อ",
  Questions: "ข้อ",
  Points: "คะแนน",
  "No limit": "ไม่จำกัด",
  "Created date": "วันที่สร้าง",
  "No due date  ": "ไม่ระบุ",
  "No due date ": "ไม่กำหนด",
  "No due date": "ไม่มีกำหนด",
  "Time (Minutes)": "เวลา (นาที)",
  Lesson: "เนื้อหาข้อสอบ",
  // 'Date of Accession': 'วันที่เข้าทำชุดข้อสอบ',
  // 'Date of Adding': 'วันที่นำชุดข้อสอบเข้ากลุ่ม',
};
const gradeVocab = {
  "Grade 1": "ประถม 1",
  "Grade 2": "ประถม 2",
  "Grade 3": "ประถม 3",
  "Grade 4": "ประถม 4",
  "Grade 5": "ประถม 5",
  "Grade 6": "ประถม 6",
  "Grade 7": "มัธยม 1",
  "Grade 8": "มัธยม 2",
  "Grade 9": "มัธยม 3",
  "Grade 1 ": "ป.1",
  "Grade 2 ": "ป.2",
  "Grade 3 ": "ป.3",
  "Grade 4 ": "ป.4",
  "Grade 5 ": "ป.5",
  "Grade 6 ": "ป.6",
  "Grade 7 ": "ม.1",
  "Grade 8 ": "ม.2",
  "Grade 9 ": "ม.3",
};
const subjectVocab = {
  Thai: "ภาษาไทย",
  English: "ภาษาอังกฤษ",
  Science: "วิทยาศาสตร์",
  Math: "คณิตศาสตร์",
  "Social science": "สังคมศาสตร์",
};
const tableExamVocab = {
  Name: "ชื่อชุดข้อสอบ",
  "Created by": "จัดชุดโดย",
  Grade: "ระดับชั้น",
  "Questions ": "จำนวนข้อ",
  Points: "คะแนน",
  "No limit": "ไม่จำกัด",
  Questions: "ข้อ",
  "Created date": "วันที่สร้าง",
  // 'Date of Accession': 'วันที่เข้าทำชุดข้อสอบ',
  // 'Date of Adding': 'วันที่นำชุดข้อสอบเข้ากลุ่ม',

  // 'Indefinite': 'ไม่ระบุ',
  "No due date  ": "ไม่ระบุ",
  "No due date ": "ไม่กำหนด",
  "No due date": "ไม่มีกำหนด",
  "Time (Minutes)": "เวลา (นาที)",
  "Due date": "วันที่เข้าทำ",
  // 'Limit Time': 'เวลา (นาที)', // TestingTime
};
const tableGroupVocab = {
  "Group Name": "ชื่อกลุ่ม",
  Owner: "เจ้าของกลุ่ม",
  Member: "สมาชิก",
  "Created date": "วันที่สร้าง",
};
const tablePretestVocab = {
  "All Pretest": "Pretest ทั้งหมด",
  "My Pretest": "Pretest ของฉัน",
  "Provided by": "จัดสอบโดย",
  Questions: "จำนวนข้อ",
  Points: "คะแนน",
  Time: "เวลาในการสอบ",
  "Period of time": "ระยะเวลาสอบ",
};

const GET_OUT_VOCAB = ["Unlimited", "Limit Time", "Date of ...", "Indefinite"];

export const vacabArticle = {
  hide: "ซ่อน",
  "see more": "ดูเพิ่มเติม",
};
export const vocabButtonDownload = {
  "Exam Report": "รายงานชุดข้อสอบ",
  "Member Report": "รายงานนักเรียน",
  "can not display the report": "ไม่สามารถออกรายงานได้",
  "Exam : ": "ข้อสอบ",
};
export const vocabCardExamSet = {
  ...tableExamVocab,
  "Access : On": "การเข้าถึง : เปิด",
  "Access : Off": "การเข้าถึง : ปิด",
};
export const vocabSuggestExam = {
  ...tableExamVocab,
  "Do Test": "เริ่มทำข้อสอบ",
  Unbookmark: "ยกเลิกบุ๊กมาร์ก",
  Bookmark: "บุ๊กมาร์ก",
  "Add to Group": "เพิ่มเข้ากลุ่ม",
  "An error occurred, please contact the administrator.":
    "เกิดข้อผิดพลาดกรุณาติดต่อผู้ดูแล",
  Exam: "ข้อสอบ",
  "Suggest Exam": "ข้อสอบแนะนำ",
};
export const vocabCardGroup = {
  Unpin: "ยกเลิกปักหมุด",
  Pin: "ปักหมุด",
  Share: "แชร์กลุ่ม",
  "Edit Group": "แก้ไขกลุ่ม",
  Delete: "ลบกลุ่ม",
  Leave: "ออกจากกลุ่ม",
  "Created ": "วันที่สร้าง",
  "Cancel request": "ยกเลิกคำขอ",
  "Join Group": "ขอร่วมกลุ่ม",
};
export const vocabCardHistory = {
  Result: "ดูผลสอบ",
  Answer: "ดูคำตอบ",
  Points: "คะแนน",
  Lesson: "บทเรียน",
  Questions: "ข้อ",
  Score: "คะแนนเต็ม",
};
export const vocabCriterionRandomQuestionModal = {
  ...gradeVocab,
  "Primary School": "ประถมศึกษา",
  "Secondary School": "มัธยมศึกษา",

  "Select all": "เลือกทั้งหมด",
  cancel: "ยกเลิก",
  // '': 'เกิดข้อผิดพลาดในการดึกข้อมูลวิชา ลองใหม่อีกครั้ง',
  Subject: "วิชา",
  Back: "ย้อนกลับ",
  Next: "ถัดไป",
  "Start Exam": "เริ่มทำข้อสอบ",
  Lesson: "บทเรียน",

  "Number of questions": "จำนวนข้อสอบ",
  "Lesson ": "เนื้อหาข้อสอบ",

  "Buy Package": "ซื้อแพ็กเกจ",

  "Choose your grade": "เลือกระดับชั้น",
  "Choose subject": "เลือกวิชา",
  "Choose lesson": "เลือกจำนวนข้อสอบ",
  "Exam's Detail": "รายละเอียดข้อสอบ",
  // message
  "Please choose your grade": "กรุณาเลือกระดับชั้น",
  "Please choose lesson": "กรุณาเลือกบทเรียน",
};
export const vocabFooter = {
  "Package & Price": "แพ็กเกจ & ราคา",
  Information: "ข้อมูล",
  "Legal stuff": "ข้อมูลทางกฎหมาย",
  "Terms and conditions": "เงื่อนไขและข้อตกลงในการใช้งาน",
  "Privacy policy": "นโยบายความเป็นส่วนตัว",
  Support: "ช่วยเหลือ",
  "Contact us": "ติดต่อเรา",
  "Social media": "ช่องทางรับข่าวสาร",
};
export const vocabHeaderCMS = {
  "search group": "ค้นหากลุ่ม",
};
export const vocabHeaderProfile = {
  Owner: "เจ้าของกลุ่ม",
  Members: "สมาชิก",
  Created: "วันที่สร้าง",
  "Student ID": "รหัสนักเรียน",
  Joined: "วันที่เข้าร่วม",
  "Created by": "จัดชุดโดย",
  "Group details": "รายละเอียดกลุ่ม",
};
export const vocabGuestRightNav = {
  ...authenVocab,
};
export const vocabSchoolCollabUserRightNav = {
  ...gradeVocab,
  " ": "โรงเรียน",
  Profile: "ข้อมูลผู้ใช้งาน",
  "Log out": "ออกจากระบบ",
};
export const vocabUserRightNav = {
  ...gradeVocab,
  Profile: "ข้อมูลผู้ใช้งาน",
  "Log out": "ออกจากระบบ",
};
export const vocabDropdownmore = {
  "Analysis Exam": "วิเคราะห์ผลสอบ",
  "History Exam": "ประวัติการสอบ",
  Report: "รายงานนักเรียน",
  Remove: "ลบออกจากกลุ่ม",
  // message
  "Error: query data": "เกิดข้อผิดพลาดในการเรียกข้อมูลเพิ่มเติม",
  "can not report": "ไม่สามารถออกรายงานได้",
};
export const vocabLevelMenu = {
  "Primary School": "ระดับประถม",
  "Secondary School": "ระดับมัธยมต้น",
};
export const vocabResultTesting = {
  Points: "คะแนน",
};
export const vocabMenuCardExamSet = {
  Unpin: "ยกเลิกปักหมุด",
  Pin: "ปักหมุด",
  // 'Turn on to access from this group': 'เปิดการเข้าถึงชุดข้อสอบในกลุ่ม',
  // 'Turn off to access from this group': 'ปิดการเข้าถึงชุดข้อสอบในกลุ่ม',
  "Turn on to access the group": "เปิดการเข้าถึงชุดข้อสอบในกลุ่ม",
  "Turn off to access the group": "ปิดการเข้าถึงชุดข้อสอบในกลุ่ม",
  Remove: "นำชุดข้อสอบออก",
  "Examine Again": "ทำชุดข้อสอบอีกครั้ง",
  Result: "ดูผลคะแนน",
  Answer: "ดูคำตอบ",
  "Exam's report": "รายงานผลการสอบ",
  Start: "เริ่มทำข้อสอบ",
  "Start ": "เริ่มทำชุดข้อสอบ",
};
export const vocabModalAddExamToGroup = {
  Name: "ชื่อกลุ่ม",
  Owner: "เจ้าของกลุ่ม",
  Members: "สมาชิก",
  "Created date": "วันที่สร้าง",
  "Add to group": "เพิ่มชุดข้อสอบเข้ากลุ่ม",
  Search: "ค้นหา",
  "Created by": "จัดชุดโดย",
};
export const vocabModalChangeDevice = {
  "Do you need to change device ?": "ต้องการย้ายอุปกรณ์ทำข้อสอบหรือไม่",
  "User have to start new select choice with previous timmer":
    "ผู้ใช้งานต้องเริ่มต้นทำข้อสอบใหม่ โดยที่เวลาจะถูกนับต่อ",
  "When user confirm other device can not send finish testing":
    "เมื่อทำการยื่นยันแล้วอุปกรณ์เครื่องอื่นจะไม่สามารถทำการส่งข้อสอบได้",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
};
export const vocabModalDownloadReport = {
  ...tableExamVocab,
  ...gradeVocab,
  "Member Name": "ชื่อ",
  "Student ID": "รหัสนักเรียน",
  Joined: "วันที่เข้าร่วม",
  Leave: "ออกจากกลุ่ม",
  "Search Exam Set": "ค้นหาชุดข้อสอบ",
  "Search Member ": "ค้นหานักเรียน",
  Owner: "เจ้าของกลุ่ม",
  Member: "สมาชิก",
};
export const vocabModalExamDetail = {
  ...examVocab,
  "Log In": "เข้าสู่ระบบ",
  "Examine the exam ": "ทดลองทำข้อสอบ",
  "Out of time": "หมดเวลาเข้าทำข้อสอบ",
  "Examine Again": "ทำข้อสอบอีกครั้ง",
  Examine: "เริ่มทำข้อสอบครั้งที่",
  "Complete Enter": "เข้าทำข้อสอบครบกำหนด",
  Start: "เริ่มทำข้อสอบ",
  Conditions: "กำหนดการ",
  Access: "จำนวนครั้งการเข้าทำ",
  "No limit": "ไม่จำกัด",
  " times": " ครั้ง",
  Examined: "เข้าทำแล้ว",
  "Start ": "เริ่มต้น",
  "End ": "สิ้นสุด",
  "Show score": "คะแนนหลังจบการสอบ",
  "Show answer": "เฉลยหลังจบการสอบ",
  "Switch the questions": "สลับลำดับข้อสอบ",
  "Switch the answer": "สลับลำดับตัวเลือก",
  show: "แสดง",
  "No show": "ไม่แสดง",
  On: "สลับ",
  Off: "ไม่สลับ",
  "Exam's deatil": "รายละเอียดการทำข้อสอบ",
  Lesson: "เนื้อหาข้อสอบ",
};
export const vocabModalLockExam = {
  "Exam Lock": "ข้อสอบยังไม่ถูกปลดล็อก",
  "Do you need to buy the package to unlock this exam ?":
    "ต้องการซื้อแพ็กเกจเพื่อปลดล็อกข้อสอบนี้หรือไม่",
  Cancel: "ยกเลิก",
  Buy: "ซื้อแพ็กเกจ",
};
export const vocabModalOldExam = {
  "Your testing : ": "คุณได้ทำข้อสอบ ",
  "is remain in system": "ค้างไว้",
  "Do you need to test previous testing ?": "ต้องการทำข้อสอบชุดเดิมหรือไม่",
  "previous testing": "ทำข้อสอบชุดเดิม",
  "new testing": "ทำข้อสอบชุดใหม่",
};
export const vocabModalReportMyExam = {
  ...tableGroupVocab,
  "search group": "ค้นหากลุ่ม",
  "Group Owner": "เจ้าของกลุ่ม",
};
export const vocabModalWrong = {
  Confirm: "ตกลง",
  "To Cancel is Success": "ยกเลิกแพ็กเกจสำเร็จ",
  // message
  "System notify to your email \n please check your email":
    "ระบบได้ส่งอีเมลแจ้งเตือนไปยังอีเมลของคุณ \n กรุณาตรวจสอบอีเมล",
};

export const vocabModalSuccess = {
  Confirm: "ตกลง",
};

export const vocabProgressAnalytic = {
  Result: "วิเคราะห์ผลสอบ",
  Questions: "ข้อ",
};
export const vocabRankAndScoreBoard = {
  " ": "คน",
  score: "คะแนน",
  Maximum: "สูงสุด",
  Minimum: "ต่ำสุด",
  Average: "เฉลี่ย",
  "Members : ": "จำนวนสมาชิก",
  "Lasted ranking": "อันดับล่าสุดในกลุ่ม",
  // 'Average top score': 'คะแนนที่เฉลี่ยสูงที่สุดในกลุ่ม',
  // 'Date of Test': 'วันที่สอบ',
  "  ": " น.",
  "Time used": "เวลาที่ใช้ในการสอบ",
  minutes: " นาที",
  "Highest score in group ": "คะแนนเฉลี่ยที่สูงที่สุดในกลุ่ม",
  "Lasted statistic : ": "สถิติล่าสุดวันที่",
};
export const vacabLayoutCMS = {
  Exam: "ข้อสอบ",
  Bookmark: "บุ๊กมาร์ก",
  Pretest: "Pretest",
  Package: "ซื้อแพ็กเกจ",
  Group: "กลุ่ม",
  "My Exam": "ข้อสอบของฉัน",
};
export const vocabAddATMCard = {
  "Payment Information": "ข้อมูลการชำระเงิน",
  "Try for free in 14 days, You can cancel whenever you want":
    "ทดลองงานได้ฟรี 14 วัน ไม่ผูกมัด ยกเลิกได้เมื่อต้องการ",
  Payment: "เลือกช่องทางการชำระเงิน",
  Name: "ชื่อผู้ถือบัตร",
  "ID Card": "หมายเลยบัตรเครดิต",
  "Expiration Date": "วันหมดอายุ",
  "Purchaser Detail": "รายละเอียดผู้ซื้อ",
  Email: "อีเมล",
  Back: "ย้อนกลับ",
  "Add Card": "เพิ่มบัตร",
  Cancel: "ยกเลิก",

  // message alert
  Success: "เพิ่มบัตรสำเร็จ",
  "Please fill in the name of the card holder.": "กรุณากรอกชื่อผู้ถือบัตร",
  "Please fill in your card number.": "กรุณากรอกหมายเลขบัตร",
  "Please fill in the expiration date.": "กรุณากรอกวันหมดอายุ",
  "Please fill in your CVV.": "กรุณากรอก CVV",
  "Please fill in your name correctly.": "กรุณากรอกชื่อให้ถูกต้อง",
  "Please fill in email": "กรุณากรอกอีเมล",
  "Unable to add card.Incorrect information entered.":
    "ไม่สามารถเพิ่มบัตรได้ข้อมูลที่กรอกผิดพลาด",
};
export const vocabAnswer = {
  Questions: "รายการข้อสอบ",
  "No.": "ข้อที่",
  By: "โดย",
  "Question List": "รายการข้อสอบ",
  Back: "ย้อนกลับ",
  "Report Issue": "รายงานข้อสอบ",
  Send: "รายงาน",
  Question: "คำถาม",
  Answer: "คำตอบ",
  Article: "บทความ",
  "etc.": "อื่น ๆ",
  Description: "หมายเหตุ",
  Message: "คำอธิบายเพิ่มเติม",
  // message
  "Error: query data": "เกิดข้อผิดพลาดในการดึงข้อมูล",
  "Please fill in description": "กรุณากรอกคำอธิบายเพิ่มเติม",
  "Error : Report": "เกิดข้อผิดพลาดในการรายงานข้อสอบ",
  Error: "ข้อมูลการส่งรายงานผิดพลาด",
};
export const vocabFillAnswer = {
  Solution: "เฉลยละเอียด",
};
export const vocabMultipleChoiceAnswer = {
  Solution: "เฉลยละเอียด",
};
export const vocabSubQuestionAnswer = {
  Solution: "เฉลยละเอียด",
};
export const vocabSubQuestionShortText = {
  Solution: "เฉลยละเอียด",
};
export const vocabBookmarkGuest = {
  Bookmark: "บุ๊กมาร์ก",
  "Login for use": "เข้าสู่ระบบเพื่อใช้งาน",
  "You can add the exam sets you are interested":
    "คุณสามารถเพิ่มชุดข้อสอบที่สนใจเก็บไว้ทำได้ทุกเมื่อที่ต้องการ",
  Login: "เข้าสู่ระบบ",
  "Not a member yet?": "ยังไม่ได้เป็นสมาชิก ?",
  "Sign up": "สมัครสมาชิก",
};
export const vocabBookmarkMember = {
  ...tableExamVocab,
  ...gradeVocab,
  Bookmark: "บุ๊กมาร์ก",
  "Cancel Bookmark": "ยกเลิกบุ๊กมาร์ก",
  "Start exam": "เริ่มทำข้อสอบ",
};
export const vocabGroup = {
  Unpin: "ยกเลิกปักหมุด",
  Pin: "ปักหมุด",
  Share: "แชร์กลุ่ม",
  Edit: "แก้ไขกลุ่ม",
  Delete: "ลบกลุ่ม",
  Leave: "ออกจากกลุ่ม",
  Created: "วันที่สร้าง",
  "Cancel request": "ยกเลิกคำขอ",
  "Join Group": "ขอร่วมกลุ่ม",
  "Enter Group": "เลือกกลุ่ม",
  "Create Group": "สร้างกลุ่ม",
  Name: "ชื่อกลุ่ม",
  Owner: "เจ้าของกลุ่ม",
  Members: "สมาชิก",
  Group: "กลุ่ม",
};
export const modalReportGroup = {
  Group: "กลุ่ม",
  Report: "รายงานผลการสอบ",
  Name: "ชื่อ",
  "Lasted examination": "วันที่สอบล่าสุด",
  "Average Time": "เวลาเฉลี่ย",
  "Average Score": "คะแนนเฉลี่ย",
  History: "ประวัติการสอบ",
  Result: "ดูผลคะแนน",
  Answer: "ดูคำตอบ",
};
export const vocabExam = {
  ...examVocab,
  ...tableExamVocab,
};
export const vocabGroupDetail = {
  Group: "กลุ่ม",
};
export const vocabGroupDetailFilter = {
  "All Exams": "ข้อสอบทั้งหมด",
  Done: "ข้อสอบที่ทำแล้ว",
  "In progress": "ข้อสอบที่ยังไม่ได้ทำ",
};
export const vocabGroupDetailNavbar = {
  "Share Group": "แชร์กลุ่ม",
  "Add Exam": "เพิ่มชุดข้อสอบ",
};
export const vocabGroupDetailTab = {
  // 'Exams': 'ชุดข้อสอบ',
  Exams: "ข้อสอบ",
  Members: "สมาชิก",
  Joins: "ขอเข้าร่วม",
};
export const vocabHistoryInGroup = {
  Round: "ครั้งที่",
  Date: "วันที่",
  Time: "เวลาที่ใช้",
  Points: "คะแนน",
  Result: "ดูผลคะแนน",
  Answer: "ดูคำตอบ",
  group: "กลุ่ม",
  Report: "รายงานผลการสอบ",
  "Student ID": "รหัสนักเรียน",
  Joined: "วันที่เข้าร่วม",
};
export const vocabMember = {
  Owner: "เจ้าของกลุ่ม",
  Teacher: "ครู/อาจารย์",
  Student: "นักเรียน",
  Name: "ชื่อ",
  Status: "สถานะ",
  "Student ID": "รหัสนักเรียน",
  Selected: "เลือก",
  items: "รายการ",
  Search: "ค้นหา",
  Joined: "วันที่เข้าร่วม",
  Leaved: "ออกจากกลุ่ม",
};
export const vocabGroupStudentAnalysis = {
  "Top - Low Score": "คะแนน สูงสุด - ต่ำสุด",
  "Low - Top Score": "คะแนน ต่ำสุด - สูงสุด",
  "Not found list in ": "ไม่พบข้อมูลในระดับชั้น",
  Group: "กลุ่ม",
  Analysis: "วิเคราะห์ผลสอบ",
  Lesson: "บทเรียน",
  Indication: "ตัวชี้วัด",
  "Not found data": "ไม่พบข้อมูล",
};
export const vocabListAnalysisTable = {
  Lesson: "รายการบทเรียน",
  Indicator: "รายการตัวชี้วัด",
  comprehension: "ความเข้าใจ",
};
export const vocabGroupHistoryTesting = {
  "Top - Low Score": "ทำข้อสอบ ล่าสุด - เก่าสุด",
  "Low - Top Score": "ทำข้อสอบ เก่าสุด - ล่าสุด",
  group: "กลุ่ม",
  "history of ": "ประวัติ",
};
export const vocabModalActionGroup = {
  "Join the Group": "เข้าร่วมกลุ่ม",
  "Cancel to join": "ยกเลิกขอเข้ากลุ่ม",
  Cancel: "ยกเลิกคำขอ",
  "Leave Group": "ออกจากกลุ่ม",
  "Delete Group": "ลบกลุ่ม",

  Join: " เข้าร่วมกลุ่ม ",
  "Leave group": " ออกจากกลุ่ม ",
  Delete: " ลบกลุ่ม ",
  " Cancel ": " ยกเลิก ",

  "Do you need to join the group ?": "ต้องการเข้าร่วมกลุ่มหรือไม่ ?",
  "Do you need to cancel to join ?": "ต้องการยกเลิกขอเข้ากลุ่มหรือไม่ ?",
  "Do you want to leave from this group?": "ต้องการออกจากกลุ่มหรือไม่ ?",
  "Do you want to delete this group?": "ต้องการลบกลุ่มหรือไม่ ?",
};
// invalid
export const vocabModalAddExam = {
  Exam: "ชื่อชุดข้อสอบ",
  "Add Exam": "เพิ่มชุดข้อสอบ",
  Created: "วันที่สร้าง",
  Owner: "เจ้าของกลุ่ม",
  Members: "สมาชิก",
  "Add to group": "เพิ่มชุดข้อสอบเข้ากลุ่ม",
  ...tableExamVocab,
};
export const vocabModalCreateGroup = {
  "Spin 90°": "หมุน 90°",
  "Edit the Group": "แก้ไขกลุ่ม",
  "Create the Group": "สร้างกลุ่ม",
  "Group Name": "ชื่อกลุ่ม",
  "Group Detail": "รายละเอียดกลุ่ม",
  Create: " สร้างกลุ่ม ",
  Edit: " แก้ไขกลุ่ม ",
  // message
  "please Fill in group name": "กรุณากรอกชื่อกลุ่ม",
};
export const vocabModalDeleteExam = {
  ...tableExamVocab,
  "Remove the Exam": "นำข้อสอบออก",
  " ": "ข้อ",
  "  ": "คะแนน",
  "   ": " นาที",
  // 'Unlimited': 'ไม่จำกัดเวลา',
  Start: "เริ่มต้น",
  End: "สิ้นสุด",

  "Do you need to remove exam ?": "ต้องการลบข้อสอบออกจากกลุ่มหรือไม่ ?",
  Cancel: " ยกเลิก ",
  Remove: " นำข้อสอบออก ",
};
export const vocabModalDeleteMember = {
  "Remove the Member": "ลบสมาชิก",
  Amount: "จำนวน",
  items: "รายการ",
  "Do you need to remove member ?": "ต้องการลบสมาชิกออกจากกลุ่มหรือไม่ ?",
  Cancel: " ยกเลิก ",
  Remove: " ลบสมาชิก ",
};
export const vocabModalShareGroup = {
  members: "คน",
  " ": "ครู",
  "Scan this QR code to join the group.":
    "สามารถเข้าร่วมกลุ่มได้โดยการสแกนคิวอาร์โค้ดนี้",
  "Reset QR Code": "Reset QR Code",
  "Copy link": "คัดลอกลิงก์",
  " Save": "บันทึกรูปภาพ",
};
export const vocabGroupReportInfo = {
  "Created by": "จัดชุดโดย",
  "Created date": "วันที่สร้าง",
  "Exam details": "รายละเอียดข้อสอบ",
};
export const vocabReportDetails = {
  Detail: "รายละเอียด",
  Questions: "ข้อ",
  Points: "คะแนน",
  Minutes: "นาที",
  "No limit": "ไม่จำกัด",
};
export const vocabReportLesson = {
  Questions: "ข้อ",
  Lessons: "เนื้อหาที่ออกสอบ",
};
export const vocabReportSchedule = {
  Conditions: "กำหนดการ",
  Access: "จำนวนที่สามารถเข้าทำได้",
  "No limit": "ไม่จำกัดจำนวน",
  " ": " ครั้ง",
  start: "เริ่มต้น",
  end: "สิ้นสุด",
  "Show answer": "แสดงคำตอบ",
  "Show score": "แสดงคะแนน",
  Show: "แสดง",
  "No Show": "ไม่แสดง",
  "Switch the questions": "สลับลำดับข้อสอบ",
  "Switch the answers": "สลับลำดับตัวเลือก",
  On: "สลับ",
  Off: "ไม่สลับ",
};
export const vocabRequest = {
  Teacher: "ครู/อาจารย์",
  Student: "นักเรียน",
  Name: "ชื่อ",
  Role: "สถานะ",
  "Student ID": "รหัสนักเรียน",
  approve: "อนุมัติ",
  disapprove: "ไม่อนุมัติ",
  Selected: "เลือก",
  items: "รายการ",
  search: "ค้นหา",
};
export const vocabHome = {
  "": "",
};
export const vocabHomeExamSuggestion = {
  Suggest: "ข้อสอบแนะนำ",
  "More > ": "ดูเพิ่มเติม",
};
export const vocabHomeSubjectList = {
  Subject: "ประเภทวิชา",
};
export const vocabLogin = {
  ...authenVocab,
  "Username, Email": "Username / อีเมล",
  Password: "รหัสผ่าน",
  "Forget password?": "ลืมรหัสผ่าน",
  or: "หรือ",
  "Log in with Facebook": "เข้าสู่ระบบด้วย facebook",
  Skip: "ข้ามขั้นตอนนี้",
};
export const vocabMyExam = {
  ...gradeVocab,
  ...tableExamVocab,
  ...examVocab,
  "My Exam": "ข้อสอบของฉัน",
  ExamSet: "ชื่อชุดข้อสอบ",
  "Created by": "จัดชุดโดย",
  Unpin: "ยกเลิกปักหมุด",
  Pin: "ปักหมุด",
  Unbookmark: "ยกเลิกบุ๊กมาร์ก",
  Bookmark: "บุ๊กมาร์ก",
  Edit: "แก้ไขชุดข้อสอบ",
  "Add Exam to Group": "เพิ่มเข้ากลุ่ม",
  Report: "รายงานชุดข้อสอบ",
  "Create Exam Set": "สร้างชุดข้อสอบ",
};
export const vocabMyExamReport = {
  "My Exam": "ข้อสอบของฉัน",
  "Exam's report ": "รายงานชุดข้อสอบ",
  Group: "กลุ่ม",
  Ranking: "อันดับ",
};
// invalid
export const vocabTableGroup = {
  ...tableGroupVocab,
  Name: "ชื่อกลุ่ม",
  Owner: "เจ้าของกลุ่ม",
  Member: "สมาชิก",
  "Created date": "วันที่สร้าง",
  Maximum: "คะแนนสูงสุด",
  Minimum: "คะแนนต่ำสุด",
  Average: "คะแนนเฉลี่ย",
  "Exam's report": "รายงานผลการสอบ",
};
export const vocabTableRanking = {
  Name: "ชื่อ",
  "Student ID": "รหัสนักเรียน",
  Group: "กลุ่ม",
  Date: "วันที่",
  Time: "เวลาล่าสุด",
  Average: "คะแนนเฉลี่ย",
  "Result Exam": "ประวัติการสอบ",
};
export const vocabCategory = {
  "Profile : Package": "ข้อมูลผู้ใช้งาน",
  "Purchase Package : Select Grades": "ซื้อแพ็กเกจ : เลือกระดับชั้น",
  "Select Package": "ซื้อแพ็กเกจ : เลือกแพ็กเกจ",
  Shop: "ร้านค้า",
  "Select Package ": "เลือกแพ็กเกจ",
  "Go Back": "ย้อนกลับ",
  " Confirm ": "ยืนยัน",
  Confirm: "ยืนยันคำสั่งซื้อ",
  "Change Package": "เปลี่ยนแพ็กเกจ",
};
export const vocabPackage = {
  Quesitions: "ข้อ",
  "Shop : Choose your grade": "ซื้อแพ็กเกจ : เลือกระดับชั้น",
  "You can take as many examinations as you want.":
    "สามารถทำข้อสอบในระดับชั้นที่เลือกได้ไม่จำกัด",
  "My Package": "แพ็กเกจของฉัน",
  Next: "ถัดไป",
  "Fail Payment": "ชำระเงินไม่สำเร็จ",
  Free: "ฟรี",
  Paid: "ทำรายการเมื่อ",
  "Get Error from Payment": "เกิดข้อผิดพลาดในการชำระเงิน",
  // message
  "Please Choose a grade": "กรุณาเลือกระดับชั้น",
  Price: "ราคา",
};
export const vocabPurchase = {
  "Select Grades": "เลือกระดับชั้น",
  Statistic: "สถิติการสอบ",
  Package: "เลือกแพ็กเกจ",
  "Order ": "รายการสั่งซื้อ",
  "Package : Order": "ซื้อแพ็กเกจ : รายการสั่งซื้อ",
  Order: "คำสั่งซื้อ",
  Price: "ราคา",
  "Select Payment": "เลือกช่องทางการชำระเงิน",
  "Add Card": "เพิ่มบัตร",
  "*Default": "*ค่าเริ่มต้น",
  "Expiration Date": "หมดอายุ",
  Back: "ย้อนกลับ",
  Success: "ชำระเงินสำเร็จ",
  Confirm: "ตกลง",
  "Confirm ": "ชำระเงิน",
  Fail: "ชำระเงินไม่สำเร็จ",
  // message
  "The system has emailed the receipt to your email.":
    "ระบบได้ส่งอีเมลใบเสร็จไปยังอีเมลของคุณ",
  "The selected card will be set as the default card.":
    "บัตรที่เลือกจะถูกตั้งค่าเป็นบัตรเริ่มต้น",
  "Please check your inbox in email": "กรุณาตรวจสอบอีเมล",
};
export const vocabPretest = {
  ...tablePretestVocab,
  "All Pretest": "Pretest ทั้งหมด",
  "My Pretest": "Pretest ของฉัน",
  Minutes: "นาที",
};
export const vocabPretestCategories = {
  Lesson: "เนื้อหาที่ออกสอบ",
  Questions: "ข้อ",
};
export const vocabDetailAndDescription = {
  Detail: "รายละเอียด",
  Questions: "ข้อ",
  Points: "คะแนน",
  Minutes: "นาที",
};
export const vocabHistoryPretest = {
  History: "ประวัติการสอบ",
  Pretest: "ผลสอบรอบจริง",
  "History ": "ประวัติคะแนน",
  Analysis: "วิเคราะห์ผลสอบ",
};
export const vocabAnalysis = {
  "Lesson Detail": "รายละเอียดสาระการเรียนรู้",
};
export const vocabHistoryScorePretest = {
  " History  ": "ประวัติการสอบ",
  Maximum: "คะแนนสูงสุด",
  Minimum: "คะแนนต่ำสุด",
  Average: "คะแนนเฉลี่ย",
  "History of results": "รายละเอียดคะแนนสอบ",
  Pretest: "สอบจริง",
  Practice: "ฝึกซ้อม",
  "Result >": "ผลการสอบ >",
};
export const vocabCardDashboard = {};
export const vocabNotAnnouce = {
  " ": " น.",
  "Declare result after end the examination": "ประกาศผลสอบหลังปิด Pretest",
};
export const vocabRanking = {
  Ranking: "อันดับที่ได้",
  "From the entire examinee": "จำนวนผู้สอบทั้งหมด",
  Date: "วันที่สอบ",
  "Results Detail": "รายละเอียดผลการสอบ",
  Score: "คะแนนที่ได้",
  "Standard Score": "คะแนนมาตรฐาน",
  "Full Score": "คะแนนเต็ม",
  "Average Score": "ค่าเฉลี่ย",
  "Standard Deviation": "ส่วนเบี่ยงเบน",
  Maximum: "สูงสุด",
  Minimum: "ต่ำสุด",
  Time: "เวลาที่ใช้ในการสอบ",
  "Average Time": "เวลาเฉลี่ยในการสอบ",
  " ": " น.",
};
export const vocabResultPretest = {
  By: "โดย",
  Start: "เริ่มสอบ",
  End: "ส่งคำตอบ",
  "Declare result after end the Pretest": "ประกาศผลหลังปิด Pretest",
  " ": " น.",
};
export const vocabButtonAnswer = {
  "See Answer": "ดูเฉลย",
  "Open Answer": "เปิดให้ดูเฉลยได้เมื่อถึง",
  " ": " น.",
};
export const vocabButtonBack = {
  Back: "ย้อนกลับ",
  "<": "<",
};
export const vocabButtonTestAgain = {
  "More Practice": "ฝึกซ้อม",
  "More Practice ": "ทำต่อ",
  "Open start": "เปิดให้ทำรอบสอบจริงเมื่อถึง",
  " ": " น.",
};
export const vocabTextSuccess = {
  ส่งข้อสอบสำเร็จ: "ส่งข้อสอบสำเร็จ",
};
export const vocabProgress = {
  Results: "วิเคราะห์ผลสอบ",
  Questions: "ข้อ",
};
export const vocabNameAndDuration = {
  "Created by ": "ผู้ออกข้อสอบ",
  "Provided by ": "ผู้จัดสอบ",
  Start: "เริ่มสอบรอบจริง",
  End: "หมดเวลาสอบรอบจริง",
  "Time ": "เวลา",
  " ": "น.",
};
export const vocabPretestButton = {
  Free: "ฟรี",
  Start: "เริ่มทำข้อสอบ",
  Practice: "ฝึกทำข้อสอบ",
  Loading: "กำลังโหลด",
  Baht: "บาท",
};
export const vocabPretestDetail = {
  History: "ประวัติการสอบ",
  " You can only take the actual exam ": "สามารถทำข้อสอบรอบจริงได้เพียง",
  " for the first and only time ": "ครั้งแรกและครั้งเดียว",
  " before the end of the Pretest.": "ในช่วงเวลาก่อนจบการสอบ Pretest เท่านั้น",
  "Unable to complete this test.": "ไม่สามารถทำข้อสอบชุดนี้ได้",
  "Unable to access data": "ไม่สามารถเข้าถึงเข้ามูลได้",
  "After completing the actual exam. You can practice an unlimited number of times":
    "หลังจากสอบจริงเสร็จแล้ว สามารถทำการฝึกซ้อมได้ไม่จำกัดจำนวนครั้ง",
};
export const vocabProfile = {
  History: "ประวัติการสอบ",
  "Exam Statistics": "สถิติการสอบ",
  "Edit Profile": "แก้ไขข้อมูลผู้ใช้",
  Security: "ความปลอดภัยของบัญชี",
  "Log out": "ออกจากระบบ",
  Package: "แพ็กเกจ",
};
export const vocabAccountSecurity = {
  "Your otp code is invalid": "รหัส opt ของท่านไม่ถูกต้อง",
  "This phone number is confirmed": "เบอร์โทรศัพท์นี้ได้รับการยืนยันแล้ว",
  "Security Account": "ความปลอดภัยของบัญชี",
  Profile: "ข้อมูลผู้ใช้",
  "Phone number": "เบอร์โทรศัพท์",
  "add Phone number": "เพิ่มเบอร์โทร",
  "fill in OTP code": "ใส่รหัส otp",
  "check otp code": "ตรวจสอบรหัส otp",
  Email: "อีเมล",
  "send email": "ส่งยืนยันอีเมล",
  "Old password": "รหัสผ่านเก่า",
  "New password": "รหัสผ่านใหม่",
  "Confirm new password": "ยืนยันรหัสผ่านใหม่",

  "Credit/Debit Card Account": "บัญชีบัตรเครดิต/เดบิต",
  "Add Card": "เพิ่มบัตร",
  "*Default": "*ค่าเริ่มต้น",
  Cancel: "ยกเลิก",
  Save: "บันทึก",
  // message
  "Time out for valid otp code": "หมดเวลาตรวจสอบ otp กรุณาส่งคำขออีกครั้ง",
  "please indentify same password": "กรุณาระบุรหัสผ่านให้ตรงกัน",
};
export const vocabCardDetail = {
  "Error setting up": "เกิดข้อผิดพลาดในการตั้งค่าเริ่มต้น",
  "Card Information": "ข้อมูลบัตร",
  "ID Card": "หมายเลยบัตร",
  "Card Type": "ประเภทบัตร",
  Default: "ค่าเริ่มต้น",
  Open: "เปิด",
  "Your card has expired.": "บัตรของคุณหมดอายุแล้ว",
  "This card can no longer be used for payment. Please remove the card.":
    "บัตรนี้ไม่สามารถใช้ชำระเงินได้อีก กรุณาลบบัตร",
  Profile: "ข้อมูลผู้ใช้",
  Security: "ความปลอดภัย",
  "Credit/Debit Card information": "ข้อมูลบัตรเครดิต/เดบิต",
  "Add a credit/debit card": "เพิ่มบัตรเครดิต/เดบิต",
  "Remove Card": "ลบบัตร",
};
export const vocabEditProfile = {
  "Image size less than more 2 MB": "รูปภาพขนาดไม่เกิน 2 MB",
  "Can not edit": "ไม่สามารถแก้ไขได้",
  Profile: "ข้อมูลผู้ใช้งาน ",
  "Display Name": "ข้อมูลผู้ใช้งาน",
  "Edit User": "แก้ไขข้อมูลผู้ใช้",
  Personal: "ข้อมูลส่วนตัว",
  "Display Name ": "ชื่อผู้ใช้",
  School: "โรงเรียน",
  "Teach Grade": "สอนระดับชั้น",
  Grade: "ระดับชั้น",
  "Optional more than one": "ระบุได้มากกว่า 1 รายการ",
  "Teach Subject": "วิชาที่สอน",
  "Student ID": "รหัสประจำตัว",
  Birthday: "วันเกิด",
  Gender: "เพศ",
  Male: "ชาย",
  Female: "หญิง",
  "Non-binary": "ไม่ระบุ",
  Cancel: "ยกเลิก",
  Save: "บันทึก",
};
export const vocabProfilePackage = {
  "latest - oldest": "ล่าสุด - หลังสุด",
  "oldest - latest": "หลังสุด - ล่าสุด",
  " Package ": "จัดการแพ็กเกจ",
  "Purchase History ": "ประวัติการซื้อ",
  "No Data": "ไม่มีข้อมูล",
  Package: "แพ็กเกจ",
  "Price : ": "ราคา",
  free: "ฟรี",
  Edit: "แก้ไข",
  Paid: "ทำรายการเมื่อ",
  "Payment Information": "ข้อมูลการชำระเงิน",
  "select a card": "เลือกบัตร",
  "ID Card": "หมายเลยบัตร",
  "Next Payment": "ชำระเงินครั้งต่อไป",
  "Expiration date": "ใช้ได้งานได้ถึงวันที่",
  Payment: "การชำระเงิน",
  "Failed Payment": "ชำระเงินไม่สำเร็จ",
  "Cancel Package": "ยกเลิกแพ็กเกจ",
  " ": " น.",
  // message
  "Please edit the payment method.": "กรุณาแก้ไขช่องทางการชำระเงิน",
};
export const vocabATMCard = {
  "No Data": "ไม่มีข้อมูล",
  Package: "แพ็กเกจ",
  "Price : ": "ราคา",
  Free: "ฟรี",
  Edit: "แก้ไข",
  Paid: "ทำรายการเมื่อ",
  "Payment Information": "ข้อมูลการชำระเงิน",
  "select a card": "เลือกบัตร",
  Payment: "การชำระเงิน",
};
export const vocabAddCard = {
  "There was an error sending data.": "เกิดข้อผิดพลาดในการส่งข้อมูล",
  Username: "ข้อมูลผู้ใช้",
  Security: "ความปลอดภัย",
  "Add Credit/Debit Card": "เพิ่มบัตรเครดิต/เดบิต",
  "Card's Name": "ชื่อผู้ถือบัตร",
  "Card's Name is must to be character":
    "ชื่อผู้ถือบัตรต้องเป็นตัวอักษรเท่านั้น",
  "ID Card": "หมายเลยบัตรเครดิต",
  "Expiration Date": "วันหมดอายุ",
  Email: "อีเมล",
  Cancel: "ยกเลิก",
  Save: "บันทึก",
  // message alert
  "please fill out ID Card": "กรุณากรอกหมายเลขบัตร",
  "please fill out email": "กรุณากรอกอีเมล",
  "please fill out expired date": "กรุณากรอกวันหมดอายุ",
  "please fill out email": "กรุณากรอก CVV",
};
export const vocabGeneralInfoStep = {
  "Sign up": "สมัครสมาชิก",
  Displayname: "ชื่อ",
  "Password, at least 8 characters": "รหัสผ่าน",
  Email: "อีเมล",
  Accept: "คุณยอมรับ",
  "our Terms of Use.": "ข้อตกลงและเงื่อนไขการใช้บริการ",
  "our Privacy Policy.": "นโนบายความเป็นส่วนตัว",
};
export const vocabGrade = {
  ...gradeVocab,
  "Select Grade": "เลือกระดับชั้น",
  "Elementary School": "ประถม",
  "Secondary School": "มัธยม",
  Next: "ถัดไป",
  // 'Grade 1': 'ป.1',
  // 'Grade 2': 'ป.2',
  // 'Grade 3': 'ป.3',
  // 'Grade 4': 'ป.4',
  // 'Grade 5': 'ป.5',
  // 'Grade 6': 'ป.6',
  // 'Grade 7': 'ม.1',
  // 'Grade 8': 'ม.2',
  // 'Grade 9': 'ม.3',
};
export const vocabEmailFB = {
  "You want to receive facebook email": "ต้องการใช้อีเมล Facebook",
  "for news?": "เพื่อรับข่าวสารหรือไม่?",
  "Secondary School": "มัธยม",
  // message
  "Please fill out email": "กรุณากรอกอีเมล",
};
export const vocabOTP = {
  Confirm: "ยืนยัน",
  "Security password with OTP": "รหัสผ่านรักษาความปลอดภัย OTP",
  "OTP is expired in 3 minutes": "รหัส OTP มีอายุการใช้งาน 3 นาที",
  Skip: "ข้ามขั้นตอนนี้",
};
export const vocabPretestTesting = {
  "No.": "ข้อที่",
};
export const vocabTesting = {
  Questions: "รายการข้อสอบ",
};
export const vocabFooterTesting = {
  Questions: "รายการข้อสอบ",
  "Send answer": "จบการสอบ",
};
export const vocabHeaderQuestionNum = {
  "No.": "ข้อที่",
};
export const vocabHeaderTesting = {
  By: "ผู้จัดสอบ",
};
export const vocabModalFinishExam = {
  "Do you want to end the test?": "ต้องการจบการสอบหรือไม่",
  "You answered to question": "คุณทำข้อสอบไปแล้ว",
  " ": "ข้อ",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
};
export const vocabResult = {
  "Start ": "เริ่มสอบ",
  "End ": "ส่งคำตอบ",
  By: "โดย",
};
export const vocabButtonAnswerResult = {
  "See Answer": "ดูเฉลย",
  Back: "ย้อนกลับ",
  "Open Answer": "เปิดให้ดูเฉลยได้เมื่อถึง",
  " ": " น.",
};
export const vocabButtonBackResult = {
  Back: "ย้อนกลับ",
};
export const vocabButtonTestAgainResult = {
  " More Practice ": "ทำอีกครั้ง",
  "More Practice": "ฝึกซ้อม",
  " ": " น.",
};
export const vocabResultTextSuccess = {
  Success: "ส่งข้อสอบสำเร็จ",
};
export const vocabProgressResult = {
  Results: "วิเคราะห์ผลสอบ",
  Questions: "ข้อ",
};
export const vocabVerifyEmail = {
  // message
  "confirmation result email success": "ผลการการยืนยัน Email ของคุณสำเร็จ",
  "confirmation result email unsuccessful":
    "ผลการการยืนยัน Email ของคุณไม่สำเร็จ",
};
export const vocabDataGroupCardExam = {
  ...tableExamVocab,
  "No limit": "ไม่จำกัด",
  " Minutes": " นาที",
  // 'Indefinite': 'ไม่มีกำหนด',
};
export const vocabMenuGroupReport = {
  Round: "ครั้งที่",
  Date: "วันที่",
  Time: "เวลาที่ใช้",
  Points: "คะแนน",
};
export const vocabCardPretest = {
  By: "โดย",
  "Sale ": "เปิดขายวันที่",
  "Start ": "เริ่มสอบวันที่",
  "End ": "เข้าสอบได้ถึงวันที่",
};
export const vocabTestingHistory = {
  "New - Old Exam": "ทำข้อสอบ ใหม่ - เก่า",
  Profile: "ข้อมูลผู้ใช้",
  "History Exam": "ประวัติการสอบ",
  "Old - New Exam": "ทำข้อสอบ เก่า - ใหม่",
  More: "เพิ่มเติม",
};
export const vocabTestingHistoryCard = {
  Points: "คะแนน",
  Lesson: "บทเรียน",
  Questions: "ข้อ",
  Score: "คะแนนเต็ม",
  " ": " น.",
  Result: "ดูผลสอบ",
  Answer: "ดูคำตอบ",
};
export const vocabModalResult = {
  By: "โดย",
  " ": "ครั้งที่",
};
export const vocabManagePackages = {
  Shop: "ร้านค้า",
  Package: "จัดการแพ็กเกจ",
  Price: "ราคา",
  Paid: "ทำรายการเมื่อ",
  "Payment Information": "ข้อมูลการชำระเงิน",
  "select card": "เลือกบัตร",
  "ID Card": "หมายเลยบัตร",
  "Next Payment": "ชำระเงินครั้งต่อไป",
  Cancel: "ยกเลิกแพ็กเกจ",
  Username: "ข้อมูลผู้ใช้",
  // message
  "To Cancel is Success": "ยกเลิกแพ็กเกจสำเร็จ",
};
export const vocabSelectPackageCard = {
  "Select Payment": "เลือกช่องทางการชำระเงิน",
  "add card": "เพิ่มบัตร",
  default: "ค่าเริ่มต้น",
  exp: "หมดอายุ",
  "select card": "เลือกบัตร",
  Cancel: "ยกเลิก",
  Save: "บันทึก",
};
export const vocabModalCanclePackage = {
  "Change Package": "เปลี่ยนแพ็กเกจ",
  Cancel: "ยกเลิก",
  Confirm: "ยืนยัน",
  // message
  "ระบบจะทำการยกเลิกแพ็กเกจเดิมของคุณ และสมัครแพ็กเกจใหม่":
    "ระบบจะทำการยกเลิกแพ็กเกจเดิมของคุณ และสมัครแพ็กเกจใหม่",
  "Type “ยืนยัน” for continue process": "พิมพ์ “ยืนยัน” เพื่อดำเนินการต่อ",
};
export const GroupReportStudentTabVocab = {
  Ranking: "อันดับ",
  History: "ประวัติการสอบ",
};
export const vocabForgotPassword = {
  "Forgot Password": "ลืมรหัสผ่าน",
  "Email is signed up": "อีเมลที่ใช้สมัคร",
  Next: "ถัดไป",
  Back: "ย้อนกลับ",
  "new Password": "รหัสผ่านใหม่",
  "Confirm new password": "ยืนยันรหัสผ่านใหม่",
  "Submit request": "ส่งคำร้อง",

  // message
  "Please confirm your password.": "กรุณายืนยันรหัสผ่าน",
  "Password at least 8 characters": "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
  "Please fill in your email.": "กรุณากรอก อีเมล",
};

export const vocabResetPassword = {
  "Reset Password": "ตั้งรหัสผ่านใหม่",
  "The Request was expired.": "คำร้องหมดอายุ",
  "Please resend the request and check your E-mail or SMS to reset your password":
    "กรุณาส่งคำร้องใหม่อีกครั้ง และตรวจสอบอีเมล หรือ หมายเลขโทรศัพท์ของท่าน เพื่อทำการตั้งรหัสผ่านใหม่",
  Next: "ถัดไป",
  Back: "ย้อนกลับ",
  "new Password": "รหัสผ่านใหม่",
  "Confirm new password": "ยืนยันรหัสผ่านใหม่",
  "Send new request": "ส่งคำร้องใหม่",
  "Please confirm your password.": "กรุณายืนยันรหัสผ่าน",
  "Password at least 8 characters": "รหัสผ่านอย่างน้อย 8 ตัวอักษร",
  "Please fill in your email.": "กรุณากรอก อีเมล",
};

export const vocabConfirmStep = {
  Activate: "ยืนยันตัวตน",

  "ระบบส่งรหัส OTP ให้ใหม่ กรุณาตรวจสอบอีก อีเมล์ / เบอร์โทร ครั้ง":
    "ระบบส่งรหัส OTP ให้ใหม่ กรุณาตรวจสอบอีก อีเมล์ / เบอร์โทร ครั้ง",
  OTP: "รหัส OTP",
  "Resend OTP": "ส่งรหัส OTP อีกครั้ง",
  "I haven't received my password yet.": "ยังไม่ได้รับรหัสผ่าน",
  "OTP code is valid for 3 minutes.": "รหัส OTP มีอายุการใช้งาน 3 นาที",
  Next: "ถัดไป",

  // message
  "Please OTP": "กรุณากรอกรหัส OTP",
};

export const vocabLanguage = {
  Profile: "ข้อมูลผู้ใช้งาน",
  "Select Language": "เลือกภาษา",
  Confirm: "ตกลง",
};

export const vocabNoData = {
  "No data": "ไม่พบข้อมูลกลุ่ม",
};
export const vacabModalPackage = {
  " Confirm ": "ตกลง",
  "Confirm ": "ยืนยัน",
  Cancel: "ยกเลิก",
  "“confirm”": `“ยืนยัน”`,
  " ": "หรือไม่",
  "Cancel Package": "ยกเลิกแพ็กเกจ",
  "Do you need to cancel ": "คุณต้องการยกเลิก",
  "Change Package": "เปลี่ยนแพ็กเกจ",
  "to change package": " เปลี่ยนแพ็กเกจ ",
  "Do you need ": "คุณต้องการ",
  "If cancel now You can still use the package until":
    "หากยกเลิกตอนนี้ คุณยังสามารถใช้งานแพ็กเกจได้จนถึง",
  Type: "พิมพ์",
  "You have already ": "คุณมี",
  "   ": "อยู่แล้ว",
  "The same package cannot be purchased repeatedly.":
    "ไม่สามารถซื้อแพ็กเกจเดียวกันซ้ำได้",
  "Duplicate Package": "แพ็กเกจซ้ำ",
  "to continue operation": "เพื่อดำเนินการต่อ",
  "Type is wrong": "พิมพ์ยืนยันไม่ถูกต้อง",

  "Successfully canceled the package.": "ยกเลิกแพ็กเกจสำเร็จ",
  "The system has sent a notification to your email.":
    "ระบบได้ส่งอีเมลแจ้งเตือนไปยังอีเมลของคุณ",
  "Please check your email.": "กรุณาตรวจสอบอีเมล",
  "The system has sent a notification to your email. \n Please check your email.":
    "ระบบได้ส่งอีเมลแจ้งเตือนไปยังอีเมลของคุณ \n กรุณาตรวจสอบอีเมล",
};
export const vacabCardTestingFree = {
  "Experiment to measure knowledge": "ทดลองวัดความรู้",
  "Let's practice exam!": "มาฝึกทำข้อสอบกัน!",
  Free: "ฟรี",
};
export const vocabFree = {
  ...tableExamVocab,
  "Free Exam": "ชุดข้อสอบฟรี",
};
export const vocabModalStartTrial = {
  "Take unlimited exams,": "ทำข้อสอบได้ไม่อั้น",
  anywhere: "ทุกที่",
  anytime: "ทุกเวลา",
  "Free trial for 14 days": "ทดลองใช้งานฟรี 14 วัน",
  "No service charge ": "ไม่เก็บค่าบริการ",
  " until the end of the trial period": "จนกว่าจะหมดระยะเวลาทดลองใช้",
  "Work on every ": "ใช้งานได้ทุก",
  device: "Device",
  "Track statistics and exam results": "เก็บสถิติติดตามผลสอบ",
  "No contract ": "ไม่มีสัญญาผูกมัด",
  "can be canceled anytime.": "สามารถยกเลิกได้เมื่อต้องการ",
  "Start your free trial": "เริ่มทดลองใช้งาน",
};

export const vocabStatTesting = {
  Profile: "ข้อมูลผู้ใช้",
  Statistic: "สถิติการสอบ",
};
export const vocabStatTestingDetail = {
  Profile: "ข้อมูลผู้ใช้",
  Statistic: "สถิติการสอบ",
};
export const vocabContactUs = {
  "Contact Us": "ติดต่อเรา",
  "Sobtid Edutainment Co., Ltd.": "บริษัท สอบติดเอ็ดดูเทนเมนท์ จำกัด",
  "Soi Rewadee, Rama 6 Road, ": "ซอยเรวดี ถนนพระราม 6",
  "Phayathai, Phayathai, ": "แขวงพญาไท เขตพญาไท",
  Bangkok: "กรุงเทพมหานคร",
};
