import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./firebaseMessageWorker";
// import * as Sentry from "@sentry/react"
// import { BrowserTracing } from "@sentry/tracing"
import "components/style/theme.style";
import "./global.scss";
import "assets/font/THSarabun.ttf";
import "antd/dist/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
