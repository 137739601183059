import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  GetEducationTypeService,
  getNewSuggestionExamService,
  getPublisher,
} from "api";
import { getListPretestService, searchPretestSevice } from "api/pretest";
import useAuth from "context/auth";
import { initialState, dataReducer, actionType } from "./dataReducer";
import { getBundleList, getCoreFilter, getSearchBundleList } from "api/bundle";
import _ from "lodash";

const DataStoreContext = createContext();

export function DataStoreProvider({ children }) {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const [essentailLoading, setEssentailLoading] = useState(true);

  const {
    state: { user, isAuthenticated, isLoading },
  } = useAuth();

  const fetchData = async () => {
    try {
      const educationTypeData = await GetEducationTypeService();
      const coreRes = await getCoreFilter();
      const publisherRes = await getPublisher();

      // let publisherRes;
      // setTimeout(async () => {
      //   publisherRes = await getPublisher();
      //   console.log(publisherRes); // Do something with the response
      // }, 2000);

      const allSubject = _.uniqBy(
        _.flatMap(coreRes, (level) =>
          level.grades.flatMap((grade) => grade.subjects)
        ),
        "id"
      );
      const allGrade = _.flatMap(coreRes, "grades").map(({ id, name }) => ({
        id,
        name,
      }));

      let levelName;
      if (!_.isEmpty(user)) {
        levelName = _.find(coreRes, (level) =>
          _.find(level.grades, { id: user.grade })
        );
      } else {
        levelName = _.find(coreRes, (level) => _.find(level.grades, { id: 1 }));
      }

      const modifiedEducationTypeData = educationTypeData.map((item) => {
        const shortName = item.name.substring(item.name.indexOf("ระดับ") + 5);
        return { ...item, shortName };
      });

      dispatch({
        type: actionType.FETCH_EDUCATION_TYPE,
        payload: {
          modEd: modifiedEducationTypeData,
          active: levelName,
        },
      });
      dispatch({
        type: actionType.FETCH_FILTER,
        payload: {
          core: coreRes,
          allSubject: allSubject,
          allGrade: allGrade,
        },
      });

      dispatch({
        type: actionType.FETCH_PUBLISHER,
        payload: publisherRes,
      });
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setEssentailLoading(false);
    }
  };

  const fetchPretest = async () => {
    try {
      const pretestData = await getListPretestService({
        level: state.educationType.activeLevel,
      });

      dispatch({
        type: actionType.FETCH_PRETEST,
        payload: pretestData,
      });
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const fetchBundleBySearch = async (keyword) => {
    dispatch({ type: actionType.START_FETCH_BUNDLE });
    const resBundle = await getSearchBundleList({ keyword: keyword });

    dispatch({
      type: actionType.FETCH_BUNDLE,
      payload: { bundle: resBundle },
    });
  };

  const fetchBundleByFilter = async (filter) => {
    dispatch({ type: actionType.START_FETCH_BUNDLE });

    const transmitData = {
      level: state.filter.filter.level.selected,
      grade: state.filter.filter.grade?.selected,
      subject: state.filter.filter.subject?.selected,
    };

    const resBundle = await getBundleList(transmitData);

    dispatch({
      type: actionType.FETCH_BUNDLE,
      payload: { bundle: resBundle },
    });
  };

  const fetchPretestBySearch = async (keyword) => {
    await searchPretestSevice({ keyword: keyword }).then((res) => {
      dispatch({
        type: actionType.FETCH_PRETEST,
        payload: res,
      });
    });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      if (!isLoading) {
        await fetchData();
      }
    };

    fetchAllData();
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated && user !== null) {
      dispatch({ type: actionType.CHANGE_DISPLAY_TYPE, payload: user.webView });
    }
  }, [isAuthenticated, user]);

  return (
    <DataStoreContext.Provider
      value={{
        state,
        dispatch,
        fetchBundleBySearch,
        fetchBundleByFilter,
        essentailLoading,
        fetchPretest,
        fetchPretestBySearch,
      }}
    >
      {children}
    </DataStoreContext.Provider>
  );
}

export function useDataStore() {
  return useContext(DataStoreContext);
}
