import axios, {
  handleRequest,
  setAuthorizationToken,
  setLanguageAPI,
} from "./kiddy-connector";
import * as Sentry from "@sentry/react";
import { deviceDetect } from "react-device-detect";
import { idGenerate } from "util/helper";
import {
  firebaseLS,
  identifierLocalStorage,
  otpLocalStorage,
  clearAllLocal,
  setLocalStorage,
} from "util/localstorage";
import { getTokenFierbase } from "util/firebase-connector";
import _ from "lodash";

const checkMoblieBrowser = (idRef) => {
  if (idRef.isMobile || idRef.isTablet) {
    if (
      idRef.ua.includes("EdgA") ||
      (idRef.os === "iOS" && idRef.ua.includes("EdgiOS"))
    ) {
      return "Edge";
    }
    if (
      idRef.ua.includes("Chrome") ||
      (idRef.os === "iOS" && idRef.ua.includes("CriOS"))
    ) {
      return "Chrome";
    }
    if (
      idRef.ua.includes("Firefox") ||
      (idRef.os === "iOS" && idRef.ua.includes("FxiOS"))
    ) {
      return "Firefox";
    }
    if (
      idRef.ua.includes("Opera") ||
      (idRef.os === "iOS" && idRef.ua.includes("Opera"))
    ) {
      return "Opera";
    }
    if (
      idRef.ua.includes("Safari") ||
      (idRef.os === "iOS" && idRef.ua.includes("Safari"))
    ) {
      return "Safari";
    }
  }
  return undefined;
};

const storeIdentifierLS = (user) => {
  const idRef = deviceDetect();
  const deviceType = idRef
    ? (idRef.browserName || checkMoblieBrowser(idRef)) +
      ", " +
      (idRef.osName ||
        idRef.os +
          `, ${idRef.isMobile ? "Mobile" : idRef.isTablet && "Tablet"}`)
    : "";
  user.deviceId = idGenerate(deviceType);
  user.deviceType = deviceType;
  if (user?.language) {
    setLocalStorage("_lang", user.language);
    setLanguageAPI(user.language);
  }
  user.language = user.language;
  identifierLocalStorage.set(user);
  setAuthorizationToken(user.jwtToken);
  return user;
};

const storeIdentifierLSRegister = (user) => {
  const idRef = deviceDetect();
  const deviceType = idRef
    ? (idRef.browserName || checkMoblieBrowser(idRef)) +
      ", " +
      (idRef.osName ||
        idRef.os +
          `, ${idRef.isMobile ? "Mobile" : idRef.isTablet && "Tablet"}`)
    : "";
  user.deviceId = idGenerate(deviceType);
  user.deviceType = deviceType;
  if (user?.language) {
    setLocalStorage("_lang", user.language);
    setLanguageAPI(user.language);
  }
  user.language = user.language;
  identifierLocalStorage.set(user);
  setAuthorizationToken(user.jwtToken);
  return user;
};

const attachDeviceInfo = (params) => {
  const idRef = deviceDetect();
  const deviceType = idRef
    ? (idRef.browserName || checkMoblieBrowser(idRef)) +
      ", " +
      (idRef.osName ||
        idRef.os +
          `, ${idRef.isMobile ? "Mobile" : idRef.isTablet && "Tablet"}`)
    : "";
  params.deviceId = idGenerate(deviceType);
  params.deviceType = deviceType;
  return params;
};

export const updateFirebaseToken = async (action) => {
  if (identifierLocalStorage.get()?.jwtToken) {
    if (action === "logout") {
      Sentry.addBreadcrumb({
        category: "state-auth/session",
        message: localStorage.getItem("_ident"),
      });
      Sentry.addBreadcrumb({
        category: "state-auth/jwt",
        message: identifierLocalStorage.get()?.jwtToken,
      });
      Sentry.addBreadcrumb({
        category: "page/pathname",
        message: window.location.href,
      });
      Sentry.captureException(new Error("Auth/session - logout"), {});
      const res = await resetFirebaseTokenService({ firebaseToken: "" });
      firebaseLS.clear();
      firebaseLS.set(res.currentDevice?.firebaseToken);
    }
    try {
      let firebaseToken;

      if (Object.values(await getTokenFierbase())[0] === true) {
        firebaseToken = Object.values(await getTokenFierbase())[1] || undefined;
      } else {
        firebaseToken = await getTokenFierbase();
      }

      if (localStorage.getItem("_ident") && action !== "logout") {
        Sentry.addBreadcrumb({
          category: "state-auth/session",
          message: localStorage.getItem("_ident"),
        });
        Sentry.addBreadcrumb({
          category: "state-auth/jwt",
          message: identifierLocalStorage.get()?.jwtToken,
        });
        Sentry.addBreadcrumb({
          category: "page/pathname",
          message: window.location.href,
        });
        Sentry.addBreadcrumb({
          category: "firebaseToken",
          message: firebaseToken,
        });
        Sentry.captureException(new Error("Auth/session - general"), {});

        let res;
        if (!_.isEmpty(firebaseToken.response)) {
          res = await resetFirebaseTokenService({
            firebaseToken: firebaseToken.response,
          });
        } else {
          res = await resetFirebaseTokenService({
            firebaseToken: firebaseToken,
          });
        }
        firebaseLS.clear();
        firebaseLS.set(res.currentDevice?.firebaseToken);
        if (action === "click:alert") {
          window.location.reload();
        }
      }
    } catch (error) {}
  }
};

const LoginAPI = (params) =>
  axios
    .post("/auth/login", attachDeviceInfo(params))
    .then((user) => storeIdentifierLS(user));
const LoginFacebookAPI = (params) =>
  axios
    .post("/auth/login/fb", attachDeviceInfo(params))
    .then((user) => storeIdentifierLS(user));
const RegisterAPI = async (params) => {
  const res = await axios
    .post("/auth/register", attachDeviceInfo(params))
    .then((res) => {
      return storeIdentifierLSRegister(res);
    });
  return res;
};
const GetProfileAPI = () =>
  axios.get("/auth/profile").then((user) => {
    if (user?.language) {
      setLocalStorage("_lang", user.language);
    }
    user.currentGrade = user.grade;

    return user;
  });
const EditProfileAPI = (params) => axios.patch("/auth/profile", params);
const getPaymentHistoryAPI = () =>
  axios.get("auth/payments").then((res) => res.paymentHistory);

const callbackAfterLogout = async (cb) => {
  if (await cb) {
    clearAllLocal();
    identifierLocalStorage.clear();
    firebaseLS.clear();
    return await cb;
  }
  return await cb;
};
const LogoutAPI = () =>
  callbackAfterLogout(
    axios.post("/auth/logout", {}).then((response) => {
      updateFirebaseToken("logout");
      return response;
    })
  );

const RegenerateOtpAPI = () =>
  axios.post("/auth/resend/otp", {}).then((response) => {
    otpLocalStorage.set(response);
    return response;
  });
const RegenerateVerifyLinkAPI = (data) =>
  axios.post("/auth/resend/email", { email: data });
const VerifyOtpAPI = ({ verifyToken, otpCode }) =>
  axios.post("/auth/verify/otp", {
    verifyToken: verifyToken,
    otpCode: otpCode,
  });

const forgotPasswordAPI = (params) => axios.post("/auth/forgotPass", params);
const VerifyOTPAOI = (params) =>
  axios.post("/auth/forgotPass/verifyOtp", params);
const VerifyResetPasswordTokenAPI = (token) =>
  axios.get(`/auth/forgotPass/verifyOtp/${token}`);
const resSetPassword = (param) =>
  axios.patch("/auth/forgotPass/resetPassword", param);
const resetFirebaseTokenAPI = (param) => {
  return axios.patch("/auth/session", param);
};
const sendOTPEmailAPI = (params) => axios.post("auth/email", params);
const sendOTPPhoneAPI = (params) => axios.post("auth/otp", params);
const verifyOTPBeforeRegisterAPI = (params) =>
  axios.post("auth/beforeRegistor/verifyOtp", params);

export const loginService = (credentials) =>
  handleRequest(LoginAPI(credentials));
export const loginFacebookService = (credentials) =>
  handleRequest(LoginFacebookAPI(credentials));
export const registerService = (params) => handleRequest(RegisterAPI(params));
export const getProfileService = () => handleRequest(GetProfileAPI());
export const editProfileService = (params) =>
  handleRequest(EditProfileAPI(params));
export const logoutService = () => handleRequest(LogoutAPI());
export const regenerateOtpService = () => handleRequest(RegenerateOtpAPI());
export const regenerateVerifyLinkService = (data) =>
  handleRequest(RegenerateVerifyLinkAPI(data));
export const verifyResetPasswordTokenService = (params) =>
  handleRequest(VerifyResetPasswordTokenAPI(params));
export const verifyOtpService = ({ verifyToken, otpCode }) =>
  handleRequest(VerifyOtpAPI({ verifyToken, otpCode }));
export const getPaymentHistoryService = () =>
  handleRequest(getPaymentHistoryAPI());
export const submitForgotPassword = (params) =>
  handleRequest(forgotPasswordAPI(params));
export const verifyOTPService = (params) => handleRequest(VerifyOTPAOI(params));
export const resetPassword = (param) => handleRequest(resSetPassword(param));
export const resetFirebaseTokenService = (param) =>
  handleRequest(resetFirebaseTokenAPI(param));
export const sendOTPEmailService = (params) =>
  handleRequest(sendOTPEmailAPI(params));
export const sendOTPPhoneService = (params) =>
  handleRequest(sendOTPPhoneAPI(params));
export const verifyOTPBeforeRegisterService = (params) =>
  handleRequest(verifyOTPBeforeRegisterAPI(params));
